import React from 'react'
import Helmet from 'react-helmet'
import {
  Layout,
  BannerSmall,
  BannerLinks,
  TextBox
} from '../components'

import boxes from '../components/BannerLinks/DefaultBox'

import BannerImage from '../assets/projektuebersicht.jpg'

const Datenschutz = () => (
  <Layout>
    <Helmet
      title="Datenschutzerklärung"
      meta={[
        {
          name: 'description',
          content:
            'Der Schutz Ihrer persönlichen Daten ist uns ein besonderes Anliegen. Wir verarbeiten Ihre Daten daher ausschließlich auf Grundlage der gesetzlichen Bestimmungen (DSGVO, TKG 2003). In diesen Datenschutzinformationen informieren wir Sie über die wichtigsten Aspekte der Datenverarbeitung im Rahmen unserer Website.'
        },
        {
          name: 'keywords',
          content:
            'Datenschutz holzweg, Webagentur holzweg, Datenschutz, Datenschutzerklärung, Webagentur Innsbruck'
        },
        {
          property: 'og:desription',
          content:
            'Der Schutz Ihrer persönlichen Daten ist uns ein besonderes Anliegen. Wir verarbeiten Ihre Daten daher ausschließlich auf Grundlage der gesetzlichen Bestimmungen (DSGVO, TKG 2003). In diesen Datenschutzinformationen informieren wir Sie über die wichtigsten Aspekte der Datenverarbeitung im Rahmen unserer Website.'
        },
        {
          property: 'og:image',
          content: 'https://www.holzweg.com/static/projektuebersicht-3b0d0cca7e33df5e55e7b68d779cc633.jpg'
        }
      ]}
    />
    <BannerSmall image={BannerImage} title="Cookies und mehr" />
    <TextBox
      title="Datenschutz"
      text={
        <span>
          <h2>Datenschutzerklärung</h2>
          <p>
            Der Schutz Ihrer persönlichen Daten ist uns ein besonderes Anliegen.
            Wir verarbeiten Ihre Daten daher ausschließlich auf Grundlage der
            gesetzlichen Bestimmungen (DSGVO, TKG 2003). In diesen
            Datenschutzinformationen informieren wir Sie über die wichtigsten
            Aspekte der Datenverarbeitung im Rahmen unserer Website.
          </p>
          <h2>Kontakt mit uns</h2>
          <p>
            Wenn Sie per Formular auf der Website oder per E-Mail Kontakt mit
            uns aufnehmen, werden Ihre angegebenen Daten zwecks Bearbeitung der
            Anfrage und für den Fall von Anschlussfragen sechs Monate bei uns
            gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung
            weiter.
          </p>
          <h2>Cookies</h2>
          <p>
            Unsere Website verwendet so genannte Cookies. Dabei handelt es sich
            um kleine Textdateien, die mit Hilfe des Browsers auf Ihrem
            Endgerätnabgelegt werden. Sie richten keinen Schaden an.
          </p>
          <p>
            Wir nutzen Cookies dazu, unser Angebot nutzerfreundlich zu
            gestalten. Einige Cookies bleiben auf Ihrem Endgerät gespeichert,
            bis Sie diese löschen. Sie ermöglichen es uns, Ihren Browser beim
            nächsten Besuch wiederzuerkennen.
          </p>
          <p>
            Wenn Sie dies nicht wünschen, so können Sie Ihren Browser so
            einrichten, dass er Sie über das Setzen von Cookies informiert und
            Sie dies nur im Einzelfall erlauben.
          </p>
          <p>
            Bei der Deaktivierung von Cookies kann die Funktionalität unserer
            Website eingeschränkt sein.
          </p>
          <h2>matomo</h2>
          <p>
            Unsere Website nutzt Funktionen des Webanalysedienstes matomo
            (ehemals Piwik), ein Dienst der InnoCraft Ltd., 150 Willis St, 6011
            Wellington, New Zealand, NZBN 6106769, (“matomo”) mittels
            Cookie-Technologie.
          </p>
          <p>
            Der Schutz Ihrer Daten ist uns wichtig, deshalb haben wir matomo
            zusätzlich so konfiguriert, dass Ihre IP-Adresse umgehend
            anonymisiert wird. Ein Rückschluss auf Ihre Person ist uns nicht
            möglich. Zudem werden Ihre Daten ausschließlich an unseren Server
            übertragen. Die Daten werden nicht an Drittländer übermittelt und
            verlassen somit nicht die EU.
          </p>
          <p>
            Weitere informationen zu den Nutzungsbedingungen von matomo und den
            datenschutzrechtlichen Regelungen finden Sie unter:{' '}
            <a href="https://matomo.org/privacy/" target="_blank">
              https://matomo.org/privacy/
            </a>
          </p>
          <h2>Google Analytics</h2>
          <p>Unsere Website nutzt Funktionen des Webanalysedienstes Google Analytics, der von Google LLC, 1600 Amphitheatre Parkway Mountain View, CA 94043, USA (“Google”) angeboten wird. Google Analytics verwendet Cookies um uns dabei zu helfen, zu analysieren, wie Sie diese Website verwenden.</p>
          <p> 
            Sie können die Erhebung Ihrer Daten durch Google Analytics auf dieser Website
            hier steuern. Hierzu wird ein Opt-Out-Cookie gesetzt, das die
            zukünftige Erfassung Ihrer Daten beim Besuch dieser Website
            verhindert.
          </p>

          <h2>SalesViewer®-Technologie</h2>
          <p>
            Auf dieser Webseite werden mit der SalesViewer®-Technologie der SalesViewer® 
            GmbH auf Grundlage berechtigter Interessen des Webseitenbetreibers (Art. 6 Abs.1 lit.f DSGVO) 
            Daten zu Marketing-, Marktforschungs- und Optimierungszwecken gesammelt und gespeichert.
            Hierzu wird ein javascript-basierter Code eingesetzt, der zur Erhebung unternehmensbezogener 
            Daten und der entsprechenden Nutzung dient. Die mit dieser Technologie erhobenen Daten werden 
            über eine nicht rückrechenbare Einwegfunktion (sog. Hashing) verschlüsselt. 
            Die Daten werden unmittelbar pseudonymisiert und nicht dazu benutzt, 
            den Besucher dieser Webseite persönlich zu identifizieren. Die im Rahmen von Salesviewer gespeicherten Daten werden gelöscht, 
            sobald sie für ihre Zweckbestimmung nicht mehr erforderlich sind und der 
            Löschung keine gesetzlichen Aufbewahrungspflichten entgegenstehen.
            Der Datenerhebung und -speicherung kann jederzeit mit Wirkung für die Zukunft widersprochen werden, 
            indem Sie bitte diesen Link https://www.salesviewer.com/opt-out anklicken, um die Erfassung durch SalesViewer® 
            innerhalb dieser Webseite zukünftig zu verhindern. Dabei wird ein Opt-out-Cookie für diese Webseite auf Ihrem Gerät abgelegt. 
            Löschen Sie Ihre Cookies in diesem Browser, müssen Sie diesen Link erneut anklicken
          </p>
          <h2>Facebook</h2>
          <p>
            Verwendung von Facebook-Plugins Wir haben in unsere Internetpräsenz
            sogenannte Plugins des sozialen Netzwerkes facebook.com (nachfolgend
            „Facebook”) integriert. Facebook ist ein Unternehmen der Facebook
            Inc., 1601 S. California Ave, Palo Alto, CA 94304, USA. Eine
            Auflistung und das Aussehen dieser Plugins von Facebook kann unter
            der nachfolgenden Internetadresse eingesehen werden:{' '}
            <a
              href="https://developers.facebook.com/docs/plugins/"
              target="_blank"
            >
              https://developers.facebook.com/docs/plugins/
            </a>{' '}
            Bei jedem Aufruf einer Webseite unserer Internetpräsenz, die mit
            einem solchen Plugin versehen ist, veranlasst das Plugin, dass der
            von Ihnen verwendete Browser die optische Darstellung des Plugins
            vom Facebook-Server lädt und darstellt. Durch die Einbindung der
            Plugins erhält Facebook die Information, dass Ihr Browser die
            entsprechende Seite unseres Webauftritts aufgerufen hat, auch wenn
            Sie kein Facebook-Benutzerkonto besitzen oder gerade nicht bei
            Facebook eingeloggt sind. Diese Information (einschließlich Ihrer
            IP-Adresse) wird von Ihrem Browser direkt an einen Server von
            Facebook in den USA übermittelt und dort gespeichert. Wenn Sie
            Mitglied bei Facebook und während des Besuchs unserer
            Internetpräsenz bei Facebook eingeloggt sind, erkennt Facebook durch
            die von dem Plugin gesendete Information, welche bestimmte Webseite
            unserer Internetpräsenz Sie gerade besuchen und weist dies Ihrem
            persönlichen Benutzerkonto auf Facebook zu, unabhängig davon, ob Sie
            eines der Plugins betätigen. Betätigen Sie nun eines der Plugins,
            beispielsweise durch Anklicken des „Gefällt mir”-Buttons oder durch
            die Abgabe eines Kommentares, wird dies an Ihr persönliches
            Benutzerkonto auf Facebook gesendet und dort gespeichert. Um zu
            verhindern, dass Facebook die gesammelten Daten Ihrem Benutzerkonto
            auf Facebook zuordnet, müssen Sie sich bei Facebook ausloggen, bevor
            Sie unsere Internetpräsenz besuchen. Um die Datenerhebung und
            -weitergabe Ihrer Besucherdaten durch Facebook-Plugins für die
            Zukunft zu blocken, können Sie für einige Internetbrowser unter
            nachfolgendem Link (externe Seite) ein Browser-Add-On von „ADGUARD”
            beziehen. Bitte löschen Sie das Browser-Add-On nicht, solange Sie
            die Facebook-Plugins blocken möchten:{' '}
            <a href="https://adguard.com/de/welcome.html" target="_blank">
              https://adguard.com/de/welcome.html
            </a>{' '}
            Unter der nachstehenden Internetadresse finden Sie die
            Datenschutzhinweise von Facebook mit näheren Informationen zur
            Erhebung und Nutzung von Daten durch Facebook, zu Ihren
            diesbezüglichen Rechten sowie zu den Einstellungsmöglichkeiten zum
            Schutz Ihrer Privatsphäre:{' '}
            <a href="http://www.facebook.com/policy.php" target="_blank">
              http://www.facebook.com/policy.php
            </a>
          </p>
          <h2>Youtube</h2>
          <p>
            Unsere Website nutzt allenfalls Plugins der von Google betriebenen
            Seite YouTube. Betreiber der Seiten ist die YouTube, LLC, 901 Cherry
            Ave., San Bruno, CA 94066, USA. Wenn Sie eine unserer mit einem
            YouTube-Plugin ausgestatteten Seiten besuchen, wird eine Verbindung
            zu den Servern von YouTube hergestellt, sobald Sie die Wiedergabe
            eines Videos starten. Dabei wird dem Youtube-Server mitgeteilt,
            welche unserer Seiten Sie besucht haben.
          </p>
          <p>
            Wenn Sie in Ihrem YouTube-Account eingeloggt sind ermöglichen Sie
            YouTube, Ihr Surfverhalten direkt Ihrem persönlichen Profil
            zuzuordnen. Dies können Sie verhindern, indem Sie sich aus Ihrem
            YouTube-Account ausloggen. Weitere Informationen zum Umgang von
            Nutzerdaten finden Sie in der Datenschutzerklärung von YouTube
            unter:{' '}
            <a
              href="https://www.google.de/intl/de/policies/privacy"
              target="_blank"
            >
              https://www.google.de/intl/de/policies/privacy
            </a>
          </p>
          <p>
            Diese Webseite verwendet das Produkt Google Maps von Google LLC.
            durch Nutzung dieser Webseite erklären Sie sich mit der Erfassung,
            Bearbeitung sowie Nutzung der automatisiert erhobenen Daten durch
            Google LLC, deren Vertreter sowie Dritter einverstanden. Die
            Nutzungsbedingungen von Google Maps finden sie unter
            „Nutzungsbedingungen von Google Maps”{' '}
            <a
              href="https://www.google.com/intl/de_de/help/terms_maps.html"
              target="_blank"
            >
              https://www.google.com/intl/de_de/help/terms_maps.html
            </a>
          </p>
          <h2>Newsletter</h2>
          <p>
            Sie haben die Möglichkeit, über unsere Website unseren Newsletter zu
            abonnieren. Hierfür benötigen wir Ihre E-Mail-Adresse und ihre
            Erklärung, dass Sie mit dem Bezug des Newsletters einverstanden
            sind. Sobald Sie sich für den Newsletter angemeldet haben, senden
            wir Ihnen ein Bestätigungs-E-Mail mit einem Link zur Bestätigung der
            Anmeldung. Das Abo des Newsletters können Sie jederzeit stornieren.
            Senden Sie Ihre Stornierung bitte an folgende E-Mail-Adresse{' '}
            <a href="mailto:abmeldung@holzweg.com">abmeldung@holzweg.com</a>.
            Wir löschen anschließend umgehend Ihre Daten im Zusammenhang mit dem
            Newsletter-Versand. Durch diesen Widerruf wird die Rechtmäßigkeit
            der aufgrund der Zustimmung bis zum Widerruf erfolgten Verarbeitung
            nicht berührt.
          </p>
          <h3>Inhalt des Newsletters</h3>
          <p>
            Wir versenden Newsletter, E-Mails und weitere elektronische
            Benachrichtigungen mit Informationen (nachfolgend „Newsletter“) nur
            mit der Einwilligung der Empfänger oder einer gesetzlichen
            Erlaubnis.{' '}
          </p>
          <h3>Double-Opt-In und Protokollierung</h3>
          <p>
            Die Anmeldung zu unserem Newsletter erfolgt in einem sog.
            Double-Opt-In-Verfahren. D.h. Sie erhalten nach der Anmeldung eine
            E-Mail, in der Sie um die Bestätigung Ihrer Anmeldung gebeten
            werden. Diese Bestätigung ist notwendig, damit sich niemand mit
            fremden E-Mailadressen anmelden kann.
            <br />
            <br />
            Die Anmeldungen zum Newsletter werden protokolliert, um den
            Anmeldeprozess entsprechend den rechtlichen Anforderungen nachweisen
            zu können. Hierzu gehört die Speicherung des Anmelde- und des
            Bestätigungszeitpunkts, als auch der IP-Adresse. Ebenso werden die
            Änderungen Ihrer bei MailChimp gespeicherten Daten protokolliert.
          </p>
          <h3>Einsatz des Versanddienstleisters „MailChimp“</h3>
          <p>
            Wir versenden Newsletter, E-Mails und weitere elektronische
            Benachrichtigungen mit Informationen (nachfolgend „Newsletter“) nur
            mit der Einwilligung der Empfänger oder einer gesetzlichen
            Erlaubnis. Unsere Newsletter enthalten Informationen über die
            sozialpolitische Arbeit der Diakonie und Veranstaltungstipps.
            <br />
            <br />
            Der Versand der Newsletter erfolgt mittels „MailChimp“, einer
            Newsletterversandplattform des US-Anbieters Rocket Science Group,
            LLC, 675 Ponce De Leon Ave NE #5000, Atlanta, GA 30308, USA.
            <br />
            <br />
            Die E-Mail-Adressen unserer Newsletterempfänger, als auch deren
            weitere, im Rahmen dieser Hinweise beschriebenen Daten, werden auf
            den Servern von MailChimp in den USA gespeichert. MailChimp
            verwendet diese Informationen zum Versand und zur Auswertung der
            Newsletter in unserem Auftrag. Des Weiteren kann MailChimp nach
            eigenen Informationen diese Daten zur Optimierung oder Verbesserung
            der eigenen Services nutzen, z.B. zur technischen Optimierung des
            Versandes und der Darstellung der Newsletter oder für
            wirtschaftliche Zwecke, um zu bestimmen aus welchen Ländern die
            Empfänger kommen. MailChimp nutzt die Daten unserer
            Newsletterempfänger jedoch nicht, um diese selbst anzuschreiben oder
            an Dritte weiterzugeben.
            <br />
            <br />
            Wir vertrauen auf die Zuverlässigkeit und die IT- sowie
            Datensicherheit von MailChimp. MailChimp ist unter dem
            US-EU-Datenschutzabkommen{' '}
            <a
              href="https://www.privacyshield.gov/participant?id=a2zt0000000TO6hAAG"
              target="_blank"
            >
              „Privacy Shield“
            </a>{' '}
            zertifiziert und verpflichtet sich damit die EU-Datenschutzvorgaben
            einzuhalten. Des Weiteren haben wir mit MailChimp ein
            „Data-Processing-Agreement“ abgeschlossen. Dabei handelt es sich um
            einen Vertrag, in dem sich MailChimp dazu verpflichtet, die Daten
            unserer Nutzer zu schützen, entsprechend dessen
            Datenschutzbestimmungen in unserem Auftrag zu verarbeiten und
            insbesondere nicht an Dritte weiter zu geben. Die
            Datenschutzbestimmungen von MailChimp können Sie{' '}
            <a href="http://mailchimp.com/legal/privacy/" target="_blank">
              hier einsehen
            </a>
            .
          </p>
          <h3>Anmeldedaten</h3>
          <p>
            Um sich für den Newsletter anzumelden, reicht es aus, wenn Sie Ihre
            E-Mailadresse angeben.
          </p>
          <h3>Statistische Erhebung und Analysen</h3>
          <p>
            Die Newsletter enthalten einen sog. „web-beacon“, d.h. eine
            pixelgroße Datei, die beim Öffnen des Newsletters von dem Server von
            MailChimp abgerufen wird. Im Rahmen dieses Abrufs werden zunächst
            technische Informationen, wie Informationen zum Browser und Ihrem
            System, als auch Ihre IP-Adresse und Zeitpunkt des Abrufs erhoben.
            Diese Informationen werden zur technischen Verbesserung der Services
            anhand der technischen Daten oder der Zielgruppen und ihres
            Leseverhaltens anhand derer Abruforte (die mit Hilfe der IP-Adresse
            bestimmbar sind) oder der Zugriffszeiten genutzt.
            <br />
            <br />
            Zu den statistischen Erhebungen gehört ebenfalls die Feststellung,
            ob die Newsletter geöffnet werden, wann sie geöffnet werden und
            welche Links geklickt werden. Diese Informationen können aus
            technischen Gründen zwar den einzelnen Newsletterempfängern
            zugeordnet werden. Es ist jedoch weder unser Bestreben, noch das von
            MailChimp, einzelne Nutzer zu beobachten. Die Auswertungen dienen
            uns viel mehr dazu, die Lesegewohnheiten unserer Nutzer zu erkennen
            und unsere Inhalte auf sie anzupassen oder unterschiedliche Inhalte
            entsprechend den Interessen unserer Nutzer zu versenden.
          </p>
          <h3>Online-Aufruf und Datenmanagement</h3>
          <p>
            Es gibt Fälle, in denen wir die Newsletterempfänger auf die
            Webseiten von MailChimp leiten. Z.B. enthalten unsere Newsletter
            einen Link, mit dem die Newsletterempfänger die Newsletter online
            abrufen können (z.B. bei Darstellungsproblemen im E-Mailprogramm).
            Ferner können Newsletterempfänger ihre Daten, wie z.B. die
            E-Mailadresse nachträglich korrigieren. Ebenso ist die{' '}
            <a href="http://mailchimp.com/legal/privacy/" target="_blank">
              Datenschutzerklärung
            </a>{' '}
            von MailChimp nur auf deren Seite abrufbar.
            <br />
            <br />
            In diesem Zusammenhang weisen wir darauf hin, dass auf den Webseiten
            von MailChimp Cookies eingesetzt und damit personenbezogene Daten
            durch MailChimp, deren Partnern und eingesetzten Dienstleistern
            (z.B. Google Analytics) verarbeitet werden. Auf diese Datenerhebung
            haben wir keinen Einfluss. Weitere Informationen können Sie der der{' '}
            <a href="http://mailchimp.com/legal/privacy/" target="_blank">
              Datenschutzerklärung
            </a>{' '}
            von MailChimp entnehmen. Wir weisen Sie zusätzlich auf die
            Widerspruchsmöglichkeiten in die Datenerhebung zu Werbezwecken auf
            den Webseiten{' '}
            <a href="http://www.aboutads.info/choices/" target="_blank">
              http://www.aboutads.info/choices/
            </a>{' '}
            und{' '}
            <a href="http://www.youronlinechoices.com/" target="_blank">
              http://www.youronlinechoices.com/
            </a>{' '}
            (für den Europäischen Raum) hin.
          </p>
          <h3>Kündigung/Widerruf</h3>
          <p>
            Sie können den Empfang unseres Newsletters jederzeit kündigen, d.h.
            Ihre Einwilligungen widerrufen. Damit erlöschen gleichzeitig Ihre
            Einwilligungen in dessen Versand via MailChimp und die statistischen
            Analysen. Ein getrennter Widerruf des Versandes via MailChimp oder
            der statistischen Auswertung ist leider nicht möglich.
            <br />
            <br />
            Einen Link zur Kündigung des Newsletters finden Sie am Ende eines
            jeden Newsletters.
          </p>
          <h3>Rechtsgrundlagen Datenschutzgrundverordnung</h3>
          <p>
            Entsprechend den Vorgaben der ab dem 25. Mai 2018 geltenden
            Datenschutzgrundverordnung (DSGVO) informieren wir Sie, dass die
            Einwilligungen in den Versand der E-Mailadressen auf Grundlage der{' '}
            <a href="https://dejure.org/gesetze/DSGVO/6.html" target="_blank">
              <nobr>Art. 6</nobr> Abs. 1 lit. a
            </a>
            ,{' '}
            <a href="https://dejure.org/gesetze/DSGVO/7.html" target="_blank">
              7 DSGVO
            </a>{' '}
            sowie{' '}
            <a href="https://dejure.org/gesetze/UWG/7.html" target="_blank">
              <nobr>§ 7</nobr> Abs. 2 Nr. 3, bzw. Abs. 3 UWG
            </a>{' '}
            erfolgt. Der Einsatz des Versanddienstleisters MailChimp,
            Durchführung der statistischen Erhebungen und Analysen sowie
            Protokollierung des Anmeldeverfahrens, erfolgen auf Grundlage
            unserer berechtigten Interessen gem.{' '}
            <a href="https://dejure.org/gesetze/DSGVO/6.html" target="_blank">
              <nobr>Art. 6</nobr> Abs. 1 lit. f DSGVO
            </a>
            . Unser Interesse richtet sich auf den Einsatz eines
            nutzerfreundlichen sowie sicheren Newslettersystems, das sowohl
            unseren geschäftlichen Interessen dient, als auch den Erwartungen
            der Nutzer entspricht.
            <br />
            <br />
            Wir weisen Sie ferner darauf hin, dass Sie der künftigen
            Verarbeitung Ihrer personenbezogenen Daten entsprechend den
            gesetzlichen Vorgaben gem.{' '}
            <a href="https://dejure.org/gesetze/DSGVO/21.html" target="_blank">
              <nobr>Art. 21 DSGVO</nobr>
            </a>{' '}
            jederzeit widersprechen können.
          </p>
          <h2>Internationale Datenübermittlungen</h2>
          <p>
            Manche der oben genannten Empfänger können sich außerhalb
            Österreichs bzw. der EU befinden oder verarbeiten dort Ihre
            personenbezogenen Daten. Das Datenschutzniveau in anderen Ländern
            entspricht unter Umständen nicht dem Österreichs. Wir übermitteln
            Ihre personenbezogenen Daten jedoch nur in Länder, für welche die
            EU-Kommission entschieden hat, dass sie über ein angemessenes
            Datenschutzniveau verfügen oder wir setzen Maßnahmen, um zu
            gewährleisten, dass alle Empfänger ein angemessenes
            Datenschutzniveau haben. Dazu schließen wir beispielsweise
            Standardvertragsklauseln (2010/87/EC und/oder 2004/915/EC) ab. Die
            gegebenenfalls abgeschlossenen Vereinbarungen sind auf Anfrage
            verfügbar.
          </p>
          <h2>Ihre Rechte</h2>
          <p>
            Ihnen stehen bezüglich Ihrer bei uns gespeicherten Daten
            grundsätzlich die Rechte auf Auskunft, Berichtigung, Löschung,
            Einschränkung, Datenübertragbarkeit, Widerruf und Widerspruch zu.
            Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das
            Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche
            sonst in einer Weise verletzt worden sind, können Sie sich bei uns{' '}
            <a href="mailto:office@holzweg.com">office@holzweg.com</a> oder der
            Datenschutzbehörde beschweren.
          </p>
          <h2>Sie erreichen uns unter folgenden Kontaktdaten:</h2>
          <p>
            <b>holzweg GmbH</b>
            <br />
            Adamgasse 11
            <br />A - 6020 Innsbruck
            <br />{' '}
            <a href="mailto:office@holzweg.com" target="_blank">
              office@holzweg.com
            </a>
            <br />
            <a href="tel:+43069917407403" target="_blank">
              Tel.: +43 (0) 699/ 17407403
            </a>
          </p>
        </span>
      }
    />

    <TextBox
      title="Walk with us on the holzweg"
      text="Ready für uns? Dann kontaktiere uns zu einem unverbindlichen Beratungsgespräch oder verwende unsere Call-Back Funktion. Wenn der holzweg nach Traumjob-Garantie klingt, dann schaue bei den Stellenangeboten rein oder besuche uns in den sozialen Medien!"
    />
    <BannerLinks boxes={boxes} />
  </Layout>
)

export default Datenschutz
